<template>
  <div class="condition-options">
    <div class="condition-options-list">
      <span class="condition-options-item"
            :class="{'condition-options-item-active': currentValue[valueKey] === item[valueKey]}"
            @click="handleClick(item)"
            v-for="item in data"
            :key="item[valueKey]"
      >{{ item[labelKey] }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContentConditionOptions',
  props: {
    data: Array,
    valueKey: {
      type: String,
      default: 'value'
    },
    labelKey: {
      type: String,
      default: 'label'
    }
  },
  data () {
    return {
      currentValue: {}
    }
  },
  methods: {
    handleClick (data) {
      this.currentValue = data
      this.$emit('change', data)
    }
  },
  created: function () {
  }
}
</script>

<style lang="less" scoped>
.condition-options {
  .condition-options-list {
    display: flex;
    align-items: center;

    .condition-options-item {
      padding: 0 10px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        color: #fdac1e;
      }

      &:first-child {
        padding-left: 0;
      }
    }

    .condition-options-item-active {
      color: #fdac1e;
      font-weight: bold;
    }
  }
}

</style>
