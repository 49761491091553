<template>
  <el-row>
    <el-col :span="24">
      <el-form :model="condition">
        <el-form-item>
          <el-input v-model="condition.title" placeholder="请输入标题" clearable class="form-input-title">
            <el-button slot="append" icon="el-icon-search" @click="handleTitleClick"></el-button>
          </el-input>
          <el-select v-model="condition.status" placeholder="状态" clearable class="form-input" @change="handleChange">
            <el-option :label="item.label" :value="item.value" :key="item.value" v-for="item in status"></el-option>
          </el-select>
          <el-select v-model="condition.pushStatus" placeholder="推送状态" clearable class="form-input"
                     @change="handleChange">
            <el-option :label="item.label" :value="item.value" :key="item.value" v-for="item in pushStatus"></el-option>
          </el-select>
          <el-select v-model="condition.orderBy" placeholder="排序" clearable class="form-input-sort"
                     @change="handleChange">
            <el-option :label="item.label" :value="item.value" :key="item.value" v-for="item in sortData"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <content-condition-options :data="categories" @change="handleCategoryChange" label-key="name"
                                     value-key="id"></content-condition-options>
          <content-condition-options :data="categoriesChildren" class="category-list-sub"
                                     @change="handleCategoryChildrenChange" label-key="name"
                                     value-key="id"></content-condition-options>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import { CmsCategoryApi } from '@/api'
import Dictionary from '@/utils/dictionary'
import ContentConditionOptions from './ContentConditionOptions'

export default {
  name: 'ContentCondition',
  components: {
    ContentConditionOptions
  },
  data () {
    return {
      condition: {
        status: '',
        pushStatus: '',
        categoryId: '',
        orderBy: ''
      },
      categories: [],
      categoriesChildren: [],
      currentCategory: {},
      currentCategoryChildren: {},
      status: [
        {
          label: '未发布',
          value: '1'
        },
        {
          label: '已发布',
          value: '2'
        },
        {
          label: '已下架',
          value: '3'
        }
      ],
      pushStatus: [
        {
          label: '未推送',
          value: '1'
        },
        {
          label: '已推送',
          value: '2'
        }
      ],
      sortData: [
        {
          label: '按创建时间倒序',
          value: 'creation_time desc'
        },
        {
          label: '按创建时间正序',
          value: 'creation_time'
        },
        {
          label: '按发布时间倒序',
          value: 'publish_time desc'
        },
        {
          label: '按发布时间正序',
          value: 'publish_time'
        }
      ]
    }
  },
  methods: {
    initData () {
      CmsCategoryApi.all(Dictionary.cmsCategoryType.content.code).then(data => {
        this.categories = [
          {
            name: '全部',
            id: ''
          },
          ...data
        ]
      })
    },
    handleChange () {
      const params = {}
      if (this.condition.title) {
        params.title = this.condition.title
      }
      if (this.condition.status) {
        params.status = this.condition.status
      }
      if (this.condition.categoryId) {
        params.categoryId = this.condition.categoryId
      }
      if (this.condition.pushStatus) {
        params.pushStatus = this.condition.pushStatus
      }
      if (this.condition.orderBy) {
        params.orderBy = this.condition.orderBy
      }
      this.$emit('change', params)
    },
    handleCategoryChange (data) {
      this.currentCategory = data
      if (Object.hasOwn(data, 'children')) {
        this.categoriesChildren = data.children
      } else {
        this.categoriesChildren = []
      }
      this.condition.categoryId = data.id
      this.handleChange()
    },
    handleCategoryChildrenChange (data) {
      this.currentCategoryChildren = data
      this.condition.categoryId = data.id
      this.handleChange()
    },
    handleTitleClick () {
      this.handleChange()
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style lang="less" scoped>

.category-list-sub {
  padding-left: 48px;
}

.form-input {
  width: 120px;
  margin-left: 10px;
}

.form-input-title {
  width: 600px;
}

.form-input-sort {
  width: 160px;
  margin-left: 10px;
}

</style>
