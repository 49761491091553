<template>
  <div>
    <page-header>
      <template v-slot:right>
        <router-link to="/cms/poster/edit">
          <el-button type="primary">添加海报</el-button>
        </router-link>
      </template>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <content-condition @change="handleConditionChange"></content-condition>
        <div v-loading="loading">
          <div v-if="rows.length > 0">
            <el-row :gutter="10">
              <el-col :span="4" v-for="row in rows" :key="row.id">
                <div class="poster-wrapper">
                  <div class="poster-item">
                    <el-tooltip :content="row.title" placement="right">
                      <el-image referrer-policy="No Referrer"
                                :src="row.imageUrl" :title="row.title" lazy :preview-src-list="imageList">
                      </el-image>
                    </el-tooltip>
                  </div>
                  <div class="toolbar">
                    <div class="info">
                      {{ row.categoryName }}
                    </div>
                    <div class="btn">
                      <el-button @click="handleGotoEditPage(row.id)" type="text">编辑
                      </el-button>
                      <el-button v-if="row.status !== 2" @click="handlePublish([row.id])" type="text">发布
                      </el-button>
                      <el-button v-if="row.status === 2" @click="handleOut([row.id])" type="text">下架
                      </el-button>
                      <el-button v-if="row.status !== 2" @click="handleRemove([row.id])" type="text">删除
                      </el-button>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[10,20,50,100]"
                    :page-size="size"
                    :background="true"
                    layout="total, sizes, prev, pager, next"
                    :total="total">
                </el-pagination>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { CmsPosterApi } from '@/api'
import { MATERIAL_STATUS } from '@/utils/contants'
import ContentCondition from './components/ContentCondition'

export default {
  name: 'Poster',
  components: { ContentCondition },
  data () {
    return {
      searchForm: {
        title: '',
        status: '',
        categoryId: '',
        pushStatus: ''
      },
      rows: [],
      page: 1,
      size: 12,
      total: 0,
      loading: false,
      imageList: []
    }
  },
  methods: {
    handleConditionChange (data) {
      this.searchForm = { ...data }
      this.handleCurrentChange(1)
    },
    handleSearch () {
      this.loading = true
      const params = {
        ...this.searchForm,
        page: this.page - 1,
        size: this.size
      }
      CmsPosterApi.list(params).then(result => {
        const data = result.rows
        if (data && data.length > 0) {
          this.rows = data
          this.total = result.total
          this.imageList = this.rows.map(it => it.imageUrl)
        } else {
          this.rows = []
          this.total = 0
          this.imageList = []
        }
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleSelectionChange (val) {
      this.selectRows = val
    },
    getRowStatus (status) {
      return MATERIAL_STATUS.getText(status)
    },
    handleRemove (ids) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      }).then(() => {
        CmsPosterApi.remove(ids).then(() => {
          this.$message.success('删除成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {
      })
    },
    handlePublish (ids) {
      this.$confirm('确定要发布吗？', '提示', {
        type: 'warning'
      }).then(() => {
        CmsPosterApi.publish(ids).then(() => {
          this.$message.success('发布成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {
      })
    },
    handleOut (ids) {
      this.$confirm('确定要下架吗？', '提示', {
        type: 'warning'
      }).then(() => {
        CmsPosterApi.out(ids).then(() => {
          this.$message.success('操作成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {
      })
    },
    handleGotoEditPage (id) {
      this.$router.push({
        name: 'PosterEdit',
        query: {
          id: id
        }
      })
    }
  },
  created: function () {
    this.handleSearch()
  }
}
</script>

<style lang="less">
.poster-wrapper {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, .08);
  margin-bottom: 10px;

  .poster-item {
    text-align: center;

    img {
      height: 385px;
    }
  }

  .toolbar {
    height: 32px;
    line-height: 32px;
    padding: 0 5px;
    display: flex;
    font-size: 12px;

    .info {
      flex: 1;
    }

    .btn {
      flex: 1;
      text-align: right;

      button {
        margin-left: 5px;
      }
    }
  }
}

</style>
